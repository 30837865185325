/* eslint-disable radix */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-expressions */
import { useApolloClient } from '@apollo/client';
// eslint-disable-next-line object-curly-newline
import { custDataNameCookie, expiredToken, features, modules } from '@config';
import { checkOtpLogin, getTokenOtp, requestOtpLogin } from '@core_modules/login/services/graphql';
import Content from '@core_modules/theme/components/header/desktop/components';
import {
    getCategories,
    getCustomer,
    getSigninMethodSocialLogin,
    getToken,
    getVesMenu,
    otpConfig,
    removeToken,
    socialLogin,
} from '@core_modules/theme/services/graphql';
import { removeIsLoginFlagging, setLogin } from '@helper_auth';
import { removeCartId } from '@helper_cartid';
import { removeCookies } from '@helper_cookies';
import { regexPhone } from '@helper_regex';
import { getLocalStorage, setLocalStorage } from '@root/core/helpers/localstorage';
import { priceVar } from '@root/core/services/graphql/cache';
import { loginConfig } from '@services/graphql/repository/pwa_config';
import { localCompare, localTotalCart } from '@services/graphql/schema/local';
import firebase from 'firebase/app';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import Router, { useRouter } from 'next/router';
import * as Yup from 'yup';

const OTP_EXPIRED_TIME_KEY = 'otpExpiredTime';
const OTP_TIMEOUT_TARGET_TIME = 'otpTimeoutTargetTime';

const useLocalStorageEffect = (key, state) => React.useEffect(() => setLocalStorage(key, state), [state]);

const CoreTopNavigation = (props) => {
    const {
        dataVesMenu: propsVesMenu,
        storeConfig,
        t,
        app_cookies,
        isLogin,
        showGlobalPromo,
        enablePopupInstallation,
        installMessage,
        isHomepage,
    } = props;

    let data = propsVesMenu;
    let loading = !propsVesMenu;
    const router = useRouter();

    if (!data && storeConfig && storeConfig.pwa) {
        const { data: dataVesMenu, loading: loadingVesMenu } = storeConfig.pwa.ves_menu_enable
            ? getVesMenu({
                  variables: {
                      alias: storeConfig.pwa.ves_menu_alias,
                  },
              })
            : getCategories();
        data = dataVesMenu;
        loading = loadingVesMenu;
    }

    const [value, setValue] = React.useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [disabled, setDisabled] = React.useState(false);
    const [loadingForm, setLoadingForm] = React.useState(false);
    const [isRequestOtpSent, setIsRequestOtpSent] = React.useState(false);
    const [errStatus, setErrStatus] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState('');

    const [getCustomerToken] = getToken();
    const [deleteTokenGql] = removeToken();
    // eslint-disable-next-line no-unused-vars
    const [cusIsLogin, setIsLogin] = React.useState(0);
    const [actSocialLogin] = socialLogin();
    const [getCustomerTokenOtp] = getTokenOtp();
    const [actRequestOtpLogin] = requestOtpLogin();
    const [actCheckOtpLogin] = checkOtpLogin();

    let customerData = {};
    if (isLogin && typeof window !== 'undefined') {
        const customer = getCustomer();
        if (customer.data) {
            customerData = customer.data;
        }
    }
    const client = useApolloClient();

    const handleLogout = async () => {
        window.backdropLoader(true);
        if (features.firebase.config.apiKey && features.firebase.config.apiKey !== '') {
            firebase
                .auth()
                .signOut()
                .then(() => {
                    // Sign-out successful.
                })
                .catch(() => {
                    // An error happened.
                });
        }
        await deleteTokenGql()
            .then(() => {
                Cookies.remove(custDataNameCookie);
                Cookies.remove('admin_id');
                removeIsLoginFlagging();
                priceVar({});
                removeCartId();
                removeCookies('uid_product_compare');
                client.writeQuery({ query: localTotalCart, data: { totalCart: 0 } });
                client.writeQuery({ query: localCompare, data: { item_count: 0 } });
                window.location.href = '/';
            })
            .catch(() => {
                window.backdropLoader(false);
                Router.push('/customer/account/login');
            });
    };

    const handleSearch = (ev) => {
        if (ev.key === 'Enter' && ev.target.value !== '') {
            Router.push(`/catalogsearch/result?q=${encodeURIComponent(value.replaceAll('"', ''))}&terms=true`);
        }
    };

    const searchByClick = () => {
        if (value !== '') {
            Router.push(`/catalogsearch/result?q=${encodeURIComponent(value)}&terms=true`);
        }
    };

    // enable recaptcha
    // eslint-disable-next-line no-unused-vars
    let enableRecaptcha = false;

    const { loading: loadingLoginConfig, data: dataLoginConfig } = loginConfig();
    if (!loadingLoginConfig && dataLoginConfig && dataLoginConfig.storeConfig && dataLoginConfig.storeConfig.pwa) {
        if (dataLoginConfig.storeConfig.pwa.recaptcha_login_enable !== null) {
            enableRecaptcha = storeConfig?.pwa?.recaptcha_enable && dataLoginConfig.storeConfig.pwa.recaptcha_login_enable;
        }
    }

    const expired = storeConfig?.oauth_access_token_lifetime_customer
        ? new Date(Date.now() + parseInt(storeConfig?.oauth_access_token_lifetime_customer, 10) * 3600000)
        : expiredToken;

    const LoginSchema = Yup.object().shape({
        username: Yup.string().email(t('validate:email:wrong')).required(t('validate:email:required')),
        password: Yup.string().required(t('validate:password:required')),
    });

    // get login method social login
    const socialLoginMethod = getSigninMethodSocialLogin();

    // Get OTP Config
    const { loading: loadingOtpConfig, data: dataOtpConfig } = otpConfig();

    let socialLoginMethodData = [];
    if (
        socialLoginMethod.data &&
        socialLoginMethod.data.getSigninMethodSocialLogin &&
        socialLoginMethod.data.getSigninMethodSocialLogin.signin_method_allowed &&
        socialLoginMethod.data.getSigninMethodSocialLogin.signin_method_allowed !== ''
    ) {
        socialLoginMethodData = socialLoginMethod.data.getSigninMethodSocialLogin.signin_method_allowed.split(',');
    }

    // eslint-disable-next-line consistent-return
    const handleSocialLogin = () => {
        if (firebase.app()) {
            try {
                const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
                    if (firebase.auth().currentUser) {
                        const fullname = user.displayName.split(' ');
                        const firstName = fullname[0];
                        let lastName = '';
                        const { email } = user;
                        fullname.forEach((entry) => {
                            if (entry !== firstName) {
                                lastName += `${entry} `;
                            }
                        });
                        firebase
                            .auth()
                            .currentUser.getIdToken(true)
                            .then((_user) => {
                                window.backdropLoader(true);
                                actSocialLogin({
                                    variables: {
                                        email,
                                        socialtoken: _user,
                                        firstname: firstName,
                                        lastname: lastName,
                                    },
                                })
                                    .then(async () => {
                                        setAnchorEl(null);
                                        setLogin(1, expired);
                                        await setIsLogin(1);
                                        if (router.route.includes('/customer/account/create')) {
                                            Router.push('/');
                                        } else {
                                            Router.reload('/');
                                        }
                                    })
                                    .catch((e) => {
                                        window.backdropLoader(false);
                                        window.toastMessage({
                                            open: true,
                                            variant: 'error',
                                            text: e.message.split(':')[0] || t('login:failed'),
                                        });
                                    });
                            });
                    }
                });
                return () => unregisterAuthObserver();
            } catch {
                null;
            }
        }
    };

    const handleSubmit = (formOtp, variables) => {
        let getTokenCustomer;
        if (formOtp === 'otp') {
            getTokenCustomer = getCustomerTokenOtp;
        } else if (formOtp === 'password') {
            getTokenCustomer = getCustomerToken;
        }
        setDisabled(true);
        setLoadingForm(true);
        window.backdropLoader(true);
        const sendData = (_data) => {
            getTokenCustomer({
                variables: _data,
            })
            .then(async (res) => {
                setAnchorEl(null);
                setErrStatus(false);
                window.backdropLoader(false);
                let token = '';
                if (formOtp === 'otp') {
                    token = res.data.internalGenerateCustomerTokenOtp.token;
                } else if (formOtp === 'password') {
                    token = res.data.internalGenerateCustomerToken.token;
                }
                if (token) {
                    setLogin(1, expired);
                    setIsLogin(1);
                    if (router.route.includes('/customer/account/create')) {
                        Router.push('/');
                    } else {
                        Router.reload('/');
                    }
                }
            })
                .catch(() => {
                setDisabled(false);
                setLoadingForm(false);
                window.backdropLoader(false);
                setErrStatus(true);
            });
        };
        sendData(variables);
    };

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            otp: '',
        },
        validationSchema: LoginSchema,
        onSubmit: (values) => {
            const variables = {
                username: values.username,
                password: values.password,
            };
            handleSubmit('password', variables);
        },
    });

    const [expiredTime, setExpiredTime] = React.useState(getLocalStorage(OTP_EXPIRED_TIME_KEY) || false);
    const [otpTimeoutTime, setOtpTimeoutTime] = React.useState(getLocalStorage(OTP_TIMEOUT_TARGET_TIME) || 0);

    useLocalStorageEffect(OTP_EXPIRED_TIME_KEY, expiredTime);
    useLocalStorageEffect(OTP_TIMEOUT_TARGET_TIME, otpTimeoutTime);

    const LoginOtpSchema = Yup.object().shape({
        phonenumber: Yup.string().required(t('validate:phoneNumber:required')).matches(regexPhone, t('validate:phoneNumber:wrong')),
    });

    const handleSendOtp = (phoneNumber) => {
        if (phoneNumber === '' || phoneNumber === undefined || phoneNumber === null) {
            window.toastMessage({
                open: true,
                text: t('common:header:requestOtpEmpty'),
                variant: 'error',
            });
        } else {
            const sendOtp = actRequestOtpLogin;
            window.backdropLoader(true);
            sendOtp({
                variables: {
                    phoneNumber,
                },
            })
            .then(() => {
                setIsRequestOtpSent(true);
                setErrStatus(false);
                setExpiredTime(false);
                const expiryConfig = dataOtpConfig
                    ? dataOtpConfig.otpConfig && parseInt(dataOtpConfig.otpConfig.otp_expired_time[0].expired_time_otp_login)
                    : 60;
                setOtpTimeoutTime(new Date().getTime() + expiryConfig * 1000);
                window.backdropLoader(false);
                window.toastMessage({
                    open: true,
                    text: t('otp:sendSuccess'),
                    variant: 'success',
                });
            })
            .catch((e) => {
                window.backdropLoader(false);
                setErrStatus(true);
                if (e.message === 'phone number is already Registered') {
                    setErrMsg(t('otp:registerOtpFailed', { phoneNumber }));
                } else if (e.message === 'Max retries exceeded') {
                    setErrMsg(t('otp:registerOtpTooManyRetries', { phoneNumber }));
                } else {
                    setErrMsg(t('otp:sendFailed'));
                }
            });
        }
    };

    const formikOtp = useFormik({
        initialValues: {
            phonenumber: '',
        },
        validationSchema: LoginOtpSchema,
        onSubmit: (values) => {
            handleSendOtp(values.phonenumber);
        },
    });

    const handleCheckOtp = () => {
        window.backdropLoader(true);
        let checkOtp = () => {};
        checkOtp = actCheckOtpLogin;

        checkOtp({
            variables: {
                phoneNumber: formikOtp.values.phonenumber,
                otp: formikOtp.values.otp,
            },
        })
            .then((res) => {
                window.backdropLoader(false);
                const isValid = res.data.checkOtpLogin.is_valid_otp;
                if (isValid) {
                    setDisabled(false);
                    window.toastMessage({
                        variant: 'success',
                        open: true,
                        text: t('otp:valid'),
                    });
                } else {
                    setDisabled(true);
                    window.toastMessage({
                        variant: 'error',
                        open: true,
                        text: t('otp:invalid'),
                    });
                }
            })
            .catch(() => {
                setDisabled(true);
                window.backdropLoader(false);
                window.toastMessage({
                    variant: 'error',
                    open: true,
                    text: t('otp:invalid'),
                });
            });
    };

    return (
        <Content
            t={t}
            formik={formik}
            formikOtp={formikOtp}
            handleSendOtp={handleSendOtp}
            handleCheckOtp={handleCheckOtp}
            isRequestOtpSent={isRequestOtpSent}
            expiredTime={expiredTime}
            setExpiredTime={setExpiredTime}
            otpTimeoutTime={otpTimeoutTime}
            setOtpTimeoutTime={setOtpTimeoutTime}
            handleSubmit={handleSubmit}
            disabled={disabled}
            setDisabled={setDisabled}
            loadingForm={loadingForm}
            setLoadingForm={setLoadingForm}
            isLogin={isLogin}
            data={data}
            loading={loading}
            storeConfig={storeConfig}
            handleSearch={handleSearch}
            searchByClick={searchByClick}
            setValue={setValue}
            customer={customerData}
            handleLogout={handleLogout}
            value={value}
            app_cookies={app_cookies}
            showGlobalPromo={showGlobalPromo}
            modules={modules}
            socialLoginMethodLoading={socialLoginMethod.loading}
            socialLoginMethodData={socialLoginMethodData}
            enablePopupInstallation={enablePopupInstallation}
            installMessage={installMessage}
            isHomepage={isHomepage}
            handleSocialLogin={handleSocialLogin}
            dataOtpConfig={dataOtpConfig}
            loadingOtpConfig={loadingOtpConfig}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            errStatus={errStatus}
            setErrStatus={setErrStatus}
            errMsg={errMsg}
        />
    );
};

export default CoreTopNavigation;
